import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_id: "",
    nombre: "",
    codigo: "",
    email: "",
    autenticado: false,
    redirect: "/"

  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
